define(['ePopup', 'cookie', '$window'], (ePopup, cookie, $window) => {

  const loyaltyWelcomeMessage = () => {
    const LOYALTY_WELCOME_MESSAGE_COOKIE_NAME = 'showLoyaltyWelcomeMessageModal';

    const component = {};

    component.config = {
      selectors: {
        content: '[data-loyaltyWelcomeMessage-content]',
        beautyProfileBtn: '[data-complete-profile]',
        pointRewardsBtn: '[data-view-points-rewards]'
      },
      classes: {
        loyaltyWelcomeMessageModal: 'loyaltyWelcomeMessage_modal'
      },
      attributes: {
        dataHref: 'data-href'
      }
    };

    component.init = (element) => {
      component.element = element;
      component.modalContent = element.querySelector(component.config.selectors.content);
      component.completeBeautyProfile = element.querySelector(component.config.selectors.beautyProfileBtn);
      component.viewPointAndRewards = element.querySelector(component.config.selectors.pointRewardsBtn);

      component.bind();

      component.epopup = new ePopup(
          component.modalContent,
          component.config.classes.loyaltyWelcomeMessageModal,
          false,
          false,
          undefined,
          component.updateLoyaltyWelcomeMessageCookie
      );

      return component;
    };

    component.bind = () => {
      component.completeBeautyProfile.addEventListener('click', component.navigateToBeautyProfile);
      component.viewPointAndRewards.addEventListener('click', component.navigateToPointsAndRewards);
    };

    component.navigateToBeautyProfile = () => {
      component.updateLoyaltyWelcomeMessageCookie();
      if(component.completeBeautyProfile.hasAttribute(component.config.attributes.dataHref)) {
        $window.location.href = component.completeBeautyProfile.getAttribute(component.config.attributes.dataHref);
      }
    };

    component.navigateToPointsAndRewards = () => {
      component.updateLoyaltyWelcomeMessageCookie();
      if(component.viewPointAndRewards.hasAttribute(component.config.attributes.dataHref)) {
        $window.location.href = component.viewPointAndRewards.getAttribute(component.config.attributes.dataHref);
      }
    };

    component.updateLoyaltyWelcomeMessageCookie = () => {
      const domain = '.' + document.domain;
      cookie.remove(LOYALTY_WELCOME_MESSAGE_COOKIE_NAME, { expires: 365, domain: domain, secure: true});
    };

    return component;
  };

  return loyaltyWelcomeMessage;
});
